import { LocalizedName } from 'api-hooks/common';
import { SimLiteModel } from 'api-hooks/sim/model';
import { Expose, Type } from 'class-transformer';
import {
  DataPlanTypeEnum,
  PaymentMethodFeeTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
} from 'common/constants/enum';

export class DiscountDetailModel {
  type: PaymentMethodFeeTypeEnum;
  @Type(() => Number)
  value: number;
}

export class SaleInvoiceMetadataModel extends LocalizedName {
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;
}

export class SaleInvoiceItemModel {
  id: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Type(() => SaleInvoiceMetadataModel)
  metadata: SaleInvoiceMetadataModel;

  @Type(() => SimLiteModel)
  sim: SimLiteModel | null;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'fx_rate' })
  @Type(() => Number)
  fxRate: number;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;
}
