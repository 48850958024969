import { LocalizedName } from 'api-hooks/common';
import { PaymentMethodModel } from 'api-hooks/payment-method';
import { ReferralModel } from 'api-hooks/referral';
import { SimLiteModel } from 'api-hooks/sim/model';
import { Expose, Type } from 'class-transformer';
import { ApiResult } from 'common/api/model';
import {
  CheckoutPromoTypeEnum,
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
} from 'common/constants/enum';

import { SaleInvoiceAvailablePaymentMethodModel } from './checkout';
import { DiscountDetailModel, SaleInvoiceItemModel } from './common';

export class SaleInvoiceBillItemModel extends LocalizedName {
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Type(() => SimLiteModel)
  sim: SimLiteModel;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'activation_at' })
  @Type(() => Date)
  activationAt: Date;
}

export class SaleInvoicePromoModel {
  code: string;

  @Expose({ name: 'is_usable' })
  isUsable: boolean;

  @Expose({ name: 'availability_reason' })
  availabilityReason: string;

  type: CheckoutPromoTypeEnum;

  @Expose({ name: 'reward_amount' })
  @Type(() => Number)
  rewardAmount: number;

  title: string;
  description: string | null;
  quota: number;

  @Expose({ name: 'valid_until' })
  @Type(() => Date)
  validUntil: Date | null;
}

export class SaleInvoiceBillModel {
  @Expose({ name: 'can_checkout' })
  canCheckout: boolean;

  @Type(() => SaleInvoiceBillItemModel)
  items: SaleInvoiceBillItemModel[];

  @Type(() => SaleInvoicePromoModel)
  promo: SaleInvoicePromoModel | null;

  // Payment Summary Start

  @Type(() => Number)
  price: number;

  @Type(() => Number)
  cashback: number | null;

  @Type(() => Number)
  discount: number | null;

  @Expose({ name: 'discount_details' })
  @Type(() => DiscountDetailModel)
  discountDetails: DiscountDetailModel | null;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  serviceFee: number;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  paymentFee: number;

  @Expose({ name: 'total_payment' })
  @Type(() => Number)
  totalPayment: number;

  @Expose({ name: 'total_payment' })
  @Type(() => Number)
  subtotal: number;

  // Payment Summary End
}

export class SaleInvoicePreparePaymentModel {
  @Expose({ name: 'can_settle' })
  canSettle: boolean;

  // User balance
  @Expose({ name: 'kuro_points_balance' })
  @Type(() => Number)
  kuroPointsBalance: number;

  @Expose({ name: 'usable_kuro_points' })
  @Type(() => Number)
  usableKuroPoints: number;

  @Expose({ name: 'payment_method' })
  @Type(() => PaymentMethodModel)
  paymentMethod: PaymentMethodModel | null;

  @Expose({ name: 'available_payment_methods' })
  @Type(() => SaleInvoiceAvailablePaymentMethodModel)
  availablePaymentMethods: SaleInvoiceAvailablePaymentMethodModel[];

  // End user balance

  // Analytics Requirement
  @Type(() => ReferralModel)
  referral?: ReferralModel;

  @Type(() => SaleInvoiceItemModel)
  items?: SaleInvoiceItemModel[];
  // End Analytics Requirement

  // Payment Summary
  @Type(() => Number)
  price: number;

  @Type(() => Number)
  discount: number | null;

  @Expose({ name: 'discount_details' })
  @Type(() => DiscountDetailModel)
  discountDetails: DiscountDetailModel;

  @Type(() => Number)
  subtotal: number;

  @Expose({ name: 'is_threshold' })
  isThreshold: boolean;

  @Type(() => Number)
  threshold: number;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  serviceFee: number;

  @Expose({ name: 'payment_fee' })
  @Type(() => Number)
  paymentFee: number;

  @Expose({ name: 'kuro_points' })
  @Type(() => Number)
  kuroPoints: number;

  @Expose({ name: 'total_payment' })
  @Type(() => Number)
  totalPayment: number;

  @Type(() => Number)
  cashback: number | null;

  // End payment summary
}

export type BillApiResult<T> = ApiResult<T> & {
  error?: boolean;
};

// Input

export type SaleInvoiceBillInput = {
  productItemId?: string;
  qty?: number;
  simIccid?: string;
  activationAt: Date | null;
  promo?: {
    code?: string;
    type?: CheckoutPromoTypeEnum;
  };
};

export type SaleInvoiceBillCartInput = {
  promo?: {
    code?: string;
    type?: CheckoutPromoTypeEnum;
  };
};

export type getShowPendingPaymentInput = {
  saleInvoiceId: string;
};
