import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { SimModel } from 'api-hooks/sim/model';
import { useGetSim, useGetSims } from 'api-hooks/sim/query';
import { QueryTransformer } from 'common/api/fetch';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';
import { isNetworkError } from 'common/api/utils';
import { useAuth } from 'common/auth';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import React from 'react';

export const ESIM_STORAGE_KEY = LocalStorageKeys.EsimCache;

export function getEsims(): ExtendedApiResult<SimModel[]> | undefined {
  if (typeof window === 'undefined') return;

  const response = localStorage.getItem(ESIM_STORAGE_KEY);
  if (!response) return;

  const result = QueryTransformer({ data: JSON.parse(response) }, SimModel);
  return result.data;
}

export function getEsimStorage(id: string | string[] | undefined) {
  if (typeof window === 'undefined') return;
  const results = getEsims()?.data || [];
  return results?.find((sim) => sim.id === id);
}

function resolveCacheOrActual<T>(
  offlineData: T | null,
  query: UseQueryResult<T, ApiError>,
): UseQueryResult<T, ApiError> {
  if (query.error && isNetworkError(query.error) && offlineData) {
    return {
      ...query,
      data: offlineData,
      error: null,
      isError: false,
      isSuccess: true,
      isLoadingError: false,
      isRefetchError: false,
      status: 'success',
    };
  }

  return {
    ...query,
    isLoading: offlineData ? false : query.isLoading,
    isFetching: offlineData ? false : query.isFetching,
    status: offlineData ? 'success' : query.status,
    data: query.data ?? offlineData ?? undefined,
  } as UseQueryResult<T, ApiError>;
}

function resolveInfiniteCacheOrActual<T>(
  offlineData: T | null,
  query: UseInfiniteQueryResult<T, ApiError>,
): UseInfiniteQueryResult<T, ApiError> {
  if (query.error && isNetworkError(query.error) && offlineData) {
    return {
      ...query,
      data: offlineData
        ? {
            pageParams: [],
            pages: [offlineData],
          }
        : {
            pageParams: [],
            pages: [],
          },
      error: null,
      isError: false,
      isSuccess: true,
      isLoadingError: false,
      isRefetchError: false,
      status: 'success',
    };
  }

  return {
    ...query,
    isLoading: offlineData ? false : query.isLoading,
    isFetching: offlineData ? false : query.isFetching,
    status: offlineData ? 'success' : query.status,
    data:
      query.data ??
      (offlineData
        ? {
            pages: [offlineData],
            pageParams: [],
          }
        : undefined) ??
      undefined,
  } as UseInfiniteQueryResult<T, ApiError>;
}

interface UseGetEsimCachedProps {
  id: string;
  cacheOnly?: boolean;
}

export function useGetEsimCached(
  props: UseGetEsimCachedProps,
): ReturnType<typeof useGetSim> {
  const { id, cacheOnly } = props;
  const query = useGetSim(
    {
      simId: id,
    },
    {
      enabled: !!id && !cacheOnly,
    },
  );

  const offlineData = React.useMemo<ApiResult<SimModel> | null>(() => {
    const data = getEsimStorage(id);
    if (data) {
      return {
        data,
      };
    }
    return null;
  }, [id]);

  React.useEffect(() => {
    if (query.error?.message) {
      notification.error({ message: query.error.message });
    }
  }, [query.error]);

  return resolveCacheOrActual(offlineData, query);
}

interface UseGetEsimsCachedProps {
  cacheOnly?: boolean;
}

export function useGetEsimsCached(props: UseGetEsimsCachedProps) {
  const { cacheOnly } = props;
  const query = useGetSims(
    {
      params: {
        limit: -1,
      },
    },
    {
      enabled: !cacheOnly,
    },
  );

  const { isAuthenticated, isError: isAuthError } = useAuth();

  // To future developers (or myself): I know you're tempted to use ``isAuthenticated`` from ``useAuth``. Don't.
  // ``isAuthenticated`` checks if the data from useGetMe exists. Meanwhile, ``isAbsolutelyUnauthenticated`` should be ABSOLUTELY sure that the user is REALLY non authenticated.
  // And the best way to be sure is if BE gives a big fat 401 status code.
  const { error } = query;
  const isAbsolutelyUnauthenticated = error?.statusCode === 401;

  React.useEffect(() => {
    // Clear cache if unauthenticated
    if (isAbsolutelyUnauthenticated) {
      localStorage.removeItem(ESIM_STORAGE_KEY);
    }
  }, [isAbsolutelyUnauthenticated]);

  const offlineData = React.useMemo<ExtendedApiResult<
    SimModel[]
  > | null>(() => {
    const response = localStorage.getItem(ESIM_STORAGE_KEY);
    // Always trust response from backend.
    const isActuallyAuthenticated = isAuthenticated;
    // If authentication is still fetching, check if access token exists. Note that we cannot guarantee that the access token is valid or correct. This may cause flickering but I don't have any better ideas.
    // isAuthenticated cannot be trusted while useGetMe is still running, so we check if there's any auth error instead. If there's an error, we can no longer trust the access token in localStorage.
    const mightBeAuthenticated =
      !isAuthError && !!localStorage.getItem(LocalStorageKeys.AuthStorage);
    const canAssumeThatUserIsAuthenticated =
      isActuallyAuthenticated || mightBeAuthenticated;

    // console.log({
    //   mightBeAuthenticated,
    //   isActuallyAuthenticated,
    //   canAssumeThatUserIsAuthenticated,
    //   isAuthenticated,
    //   isAuthError,
    //   response: !!response,
    // });

    // Condition for cached eSIM:
    // 1. The user is authenticated (either if access token exists or is actually authenticated)
    // 2. If there are any cached eSIMs
    // 3. If the query hasn't finished getting the newest eSIM data from BE. This case is not handled here.

    // This function handles condition (1) and (2)
    if (!response || !canAssumeThatUserIsAuthenticated) return;

    return QueryTransformer({ data: JSON.parse(response) }, SimModel).data;
  }, [isAuthenticated, isAuthError]);

  // NOTE: If you're debugging this. Remember that query data (not offlineData) can also be cached by queryClient, which may cause the previous data to appear again.
  // To verify that the caching system is working, log offlineData instead.
  // console.log(offlineData)

  // Return actual query if the error is an Unauthenticated error.
  if (isAbsolutelyUnauthenticated) {
    return query;
  }
  return resolveInfiniteCacheOrActual(offlineData, query);
}
