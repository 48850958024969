import { SimLiteModel, SimPlanModel } from 'api-hooks/sim/model';
import { Expose, Type } from 'class-transformer';
import { FeedbackStatusEnum } from 'common/constants/enum';
import { selectLanguage } from 'hooks/use-language';

// Model

export class FeedbackOptionModel {
  key: string;

  get label(): string {
    return selectLanguage({
      en: this.labelEn,
      id: this.labelId,
    });
  }

  @Expose({ name: 'label_en' })
  labelEn: string;

  @Expose({ name: 'label_id' })
  labelId: string;
}

export class FeedbackOptionsModel {
  @Type(() => FeedbackOptionModel)
  5: FeedbackOptionModel[];

  @Type(() => FeedbackOptionModel)
  4: FeedbackOptionModel[];

  @Type(() => FeedbackOptionModel)
  3: FeedbackOptionModel[];

  @Type(() => FeedbackOptionModel)
  2: FeedbackOptionModel[];

  @Type(() => FeedbackOptionModel)
  1: FeedbackOptionModel[];
}

export class FeedbackModel {
  id: string;
  status: FeedbackStatusEnum;

  rate: number | null;
  options: FeedbackOptionModel[] | null;
  opinion: string | null;

  @Type(() => SimLiteModel)
  sim: SimLiteModel;

  @Expose({ name: 'sim_plan' })
  @Type(() => SimPlanModel)
  simPlan: SimPlanModel;
}

// Input

export class CreateFeedbackInput {
  feedbackId: string;
  body: {
    rate: number;
    options: string[];
    opinion?: string;
  };
}
