import { getParamsInput } from 'api-hooks/common';
import { PaymentMethodModel } from 'api-hooks/payment-method';
import { Expose, Type } from 'class-transformer';
import {
  PaymentMethodTypeEnum,
  PaymentMethodStatusEnum,
  CheckoutPromoTypeEnum,
} from 'common/constants/enum';

export class SaleInvoiceCheckoutModel {
  @Expose({ name: 'sale_invoice_id' })
  saleInvoiceId: string;
}

export class SaleInvoiceAvailablePaymentMethodModel {
  label: string;
  type: PaymentMethodTypeEnum;
  // Only available for credit debit card
  status?: PaymentMethodStatusEnum;

  @Type(() => PaymentMethodModel)
  items: PaymentMethodModel[];
}

// Input

export type getSaleInvoicePromoInput = getParamsInput<{
  total_price: number;
}>;

export type SaleInvoiceCheckoutInput = {
  body: {
    productItemId?: string;
    qty?: number;
    simIccid?: string;
    activationAt: Date | null;
    promo?: {
      code?: string;
      type?: CheckoutPromoTypeEnum;
    };
  };
};

export type SaleInvoiceCheckoutCartInput = {
  body?: {
    promo?: {
      code?: string;
      type?: CheckoutPromoTypeEnum;
    };
  };
};
