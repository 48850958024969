import { getParamsInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';
import {
  SaleInvoiceStatusEnum,
  SaleInvoicePillStatusEnum,
  SaleInvoiceTypeEnum,
} from 'common/constants/enum';

import { DiscountDetailModel, SaleInvoiceItemModel } from './common';
import { SaleInvoicePaymentableModel } from './payment';

export class SaleInvoiceLiteModel {
  id: string;
  number: string;
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;

  @Type(() => Number)
  total: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'first_item' })
  @Type(() => SaleInvoiceItemModel)
  firstItem: SaleInvoiceItemModel;

  @Expose({ name: 'total_items' })
  @Type(() => Number)
  totalItems: number;
}

export class SaleInvoiceModel {
  id: string;
  number: string;
  // Disable mutating payment if type is not regular
  type: SaleInvoiceTypeEnum;
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;

  @Type(() => SaleInvoiceItemModel)
  items: SaleInvoiceItemModel[];

  @Expose({ name: 'payment_method' })
  paymentMethod: string;

  @Type(() => SaleInvoicePaymentableModel)
  paymentable: SaleInvoicePaymentableModel;

  // Dates

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Expose({ name: 'completed_at' })
  @Type(() => Date)
  completedAt: Date;

  @Expose({ name: 'canceled_at' })
  @Type(() => Date)
  canceledAt: Date;

  @Expose({ name: 'expired_at' })
  @Type(() => Date)
  expiredAt: Date;

  @Expose({ name: 'rejected_at' })
  @Type(() => Date)
  rejectedAt: Date;

  @Expose({ name: 'refunded_at' })
  @Type(() => Date)
  refundedAt: Date;

  // End Dates

  // Payment Summary

  @Type(() => Number)
  price: number;

  @Type(() => Number)
  subtotal: number;

  @Type(() => Number)
  discount: number | null;

  @Expose({ name: 'discount_details' })
  @Type(() => DiscountDetailModel)
  discountDetails: DiscountDetailModel | null;

  @Expose({ name: 'service_fee' })
  @Type(() => Number)
  serviceFee: number;

  @Expose({ name: 'payment_fee' })
  @Type(() => Number)
  paymentFee: number;

  @Expose({ name: 'kuro_points' })
  @Type(() => Number)
  kuroPoints: number;

  @Type(() => Number)
  total: number;

  @Type(() => Number)
  cashback: number | null;

  // End Payment Summary
}

export class SimPlanProgressModel {
  @Type(() => Number)
  goal: number;

  @Type(() => Number)
  progress: number;

  done: boolean;
}

export class SaleInvoiceDownloadPdfModel {
  url: string;
}

// Input

export type SaleInvoiceDownloadPdfInput = {
  saleInvoiceId: string;
};
export type getSaleInvoicesInput = getParamsInput<{
  'filter[status]': string;
}>;

export type getSaleInvoiceInput = {
  id: string;
};
