import { AvatarProfileTypeEnum } from 'api-hooks/account';
import { IdInput, getParamsInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';
import { AffiliateStatusEnum, ReferralTypeEnum } from 'common/constants/enum';

// Enums
export enum ReferralRewardTypeEnum {
  Purchase = 'purchase',
  Join = 'join',
  Refund = 'refund',
}

// Models
export class ReferralModel {
  id: string;
  code: string;
  type: ReferralTypeEnum;
  @Expose({ name: 'code_revision_at' })
  @Type(() => Date)
  /** Null -> editable */
  codeRevisionAt: Date | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

class RefereeModel {
  id: string;
  email: string;

  @Expose({ name: 'avatar_profile_type' })
  avatarProfileType: AvatarProfileTypeEnum;
}

class ReferralRewardModel {
  type: ReferralRewardTypeEnum;

  @Type(() => Date)
  datetime: Date;

  @Type(() => Number)
  amount: number | null;
}

export class RewardHistoryModel {
  @Type(() => ReferralRewardModel)
  reward: ReferralRewardModel;
  @Type(() => RefereeModel)
  referee: RefereeModel;
}

export class AffiliateModel {
  id: string;
  instagram: string;
  @Expose({ name: 'tik_tok' })
  tikTok: string;
  facebook: string;
  twitter: string;
  youtube: string;
  @Expose({ name: 'first_name' })
  firstName: string;
  @Expose({ name: 'last_name' })
  lastName: string | null;
  @Expose({ name: 'phone_number' })
  phoneNumber: string;
  status: AffiliateStatusEnum;
}

export class ReferralMonthlyPerformanceModel {
  month: number;

  @Type(() => Number)
  amount: number;

  @Type(() => Number)
  count: number;
}

// Inputs
export type ReferralMutationInput = {
  code: string;
};
export type BindReferralInput = {
  referralCode: string;
};

export type AffiliateMutationInput = {
  instagram?: string | null;
  tikTok?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  youtube?: string | null;
  phoneNumber: string | null;
};

export type RewardHistoriesInput = IdInput &
  getParamsInput<{
    year?: string;
    month?: string;
  }>;
export type ReferralPerformanceInput = IdInput &
  getParamsInput<{
    year?: string;
  }>;
