import { Type, Expose } from 'class-transformer';
import { ApiResult } from 'common/api/model';
import {
  PaymentMethodTypeEnum,
  SaleInvoicePillStatusEnum,
  SaleInvoiceStatusEnum,
} from 'common/constants/enum';

export class BankDetailModel {
  id: string;
  name: string;
  image: string;
}

export class GuideBodyModel {
  title: string;
  body: string[];
}

export class GuideModel {
  title: string;

  @Type(() => GuideBodyModel)
  body: GuideBodyModel[];
}

export class PaymentMethodEwalletModel {
  code: string;
  image: string;
}

export class PaymentableModel {
  id: string;
  provider: 'xendit';
  image: string;
  code: string;

  @Expose({ name: 'qris_string' })
  qrisString: string;

  @Type(() => GuideModel)
  guides: GuideModel[];

  @Expose({ name: 'desktop_web_checkout_url' })
  desktopWebCheckoutUrl: string;

  @Expose({ name: 'mobile_web_checkout_url' })
  mobileWebCheckoutUrl: string;

  @Expose({ name: 'mobile_deeplink_checkout_url' })
  mobileDeeplinkCheckoutUrl: string;

  @Expose({ name: 'qr_checkout_string' })
  qrCheckoutString: string;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Expose({ name: 'va_number' })
  vaNumber: string;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'bank_detail' })
  @Type(() => BankDetailModel)
  bankDetail: BankDetailModel;

  @Expose({ name: 'payment_method' })
  @Type(() => PaymentMethodEwalletModel)
  paymentMethod: PaymentMethodEwalletModel;

  @Expose({ name: 'is_available' })
  isAvailable: boolean;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

export class SaleInvoicePaymentableModel {
  id: string;
  provider: string;

  @Expose({ name: 'invoice_url' })
  invoiceUrl: string;

  @Type(() => Number)
  amount: number;
}

export class SaleInvoiceShowPendingPaymentModel {
  label: string;
  type: PaymentMethodTypeEnum;

  @Expose({ name: 'due_at' })
  @Type(() => Date)
  dueAt: Date;

  @Type(() => PaymentableModel)
  paymentable: PaymentableModel;

  @Expose({ name: 'payment_status' })
  paymentStatus: boolean;
}

export class SaleInvoiceSettlePaymentModel {
  settled: boolean;
  type: PaymentMethodTypeEnum;

  @Expose({ name: 'sale_invoice_id' })
  saleInvoiceId: string;
}

export class SaleInvoiceCheckPaymentStatusModel {
  @Expose({ name: 'payment_status' })
  paymentStatus: boolean;
}

export class SaleInvoiceShowStatusModel {
  status: SaleInvoiceStatusEnum;

  @Expose({ name: 'pill_status' })
  pillStatus: SaleInvoicePillStatusEnum;

  // GTM Analytic
  ever: boolean | null;

  @Type(() => Number)
  total: number | null;
  // End GTM Analytic
}

export type PaymentFlowApiResult<T> = ApiResult<T> & {
  redirect?: boolean;
  error?: boolean;
};

// Input

export type getShowStatusInput = {
  saleInvoiceId: string;
};

export type SaleInvoicePreparePaymentInput = {
  saleInvoiceId: string;
  body?: {
    useKuroPoints?: boolean;
    paymentMethodId?: string;
    paymentIdentifierId?: string;
  };
};

export type SaleInvoiceSettlePaymentInput = {
  saleInvoiceId: string;
  body?: {
    useKuroPoints?: boolean;
    paymentMethodId?: string;
    paymentIdentifierId?: string;
  };
};

export type SaleInvoiceProcessPendingPaymentInput = {
  saleInvoiceId: string;
  body: {
    identifier: string;
  };
};
